export const mutations = {
  PUBLIC_KEY(state, key) {
    state.publicKey = key;
  },
  USER(state, userData) {
    state.user.userEmail = userData.userEmail;
    state.user.email = userData.email;
    state.user.subId = userData.subId;
    state.user.name = userData.name;
    state.user.mrr = userData.mrr;
    /**Set Params */
    state.params.userEmail = userData.userEmail;
    state.params.email = userData.email;
    state.params.subId = userData.subId;
    state.params.portalId = userData.portalId;
  },
  PRODUCT_LIST(state, list) {
    state.productList = list.product;
    state.subscriptionProducts = list.recurring_products;
    state.oneTimeProducts = list.onetime_products;
  },
  SAVED_TRANSACTION_PRODUCT(state, data) {
    state.transactionProduct = data;
  },
  SUBSCRIBE_TRANSACTION_PRODUCT(state, data) {
    state.subscriptionProduct = data;
  },
  SAVED_SUBSCRIPTION_PRODUCT(state, data) {
    state.savedSubscriptionProduct = data;
  },
  PAYMENT_PRODUCT(state, data) {
    state.paymentProducts = data;
  },
  PAYMENT_LINK_OBJECT(state, data) {
    state.paymentLinkObj = data;
  },
  AUTHENTICATION(state, data) {
    state.authorization = data;
  },
  REFUND_PRODUCTS(state, data) {
    state.refundSubscriptions = data;
  },
  REFUND(state, data) {
    state.refundResponse = data;
  },
  LIST_FOR_PAUSE(state, data) {
    state.listForPause = data;
  },
  PAUSE(state, data) {
    state.pauseResponse = data;
  },
  COUPONS(state, data) {
    state.couponList = data;
  },

  CLIENTSECRET(state, data) {
    state.clientSecret = data;
  },
  SAVED_PAYMENT_LIST(state, data) {
    state.savedPaymentList = data;
  },
  CREATE_PAYMENT_INTENT(state, data) {
    state.paymentIntent = data;
  }
  
};
