export const state = {
  publicKey: "",
  user: {
    userEmail: "",
    email: "",
    subId: "",
    name: "",
    mrr: ""
  },
  productList: [],
  transactionProduct: {},
  subscriptionProduct: {},
  savedSubscriptionProduct: {},
  paymentProducts: {},
  paymentLinkObj: "",
  params: {
    userEmail: "",
    email: "",
    subId: ""
  },
  authorization: "",
  subscriptionProducts: [],
  oneTimeProducts: [],
  refundSubscriptions: [],
  refundResponse: "",
  listForPause: [],
  pauseResponse: "",
  couponList: [],
  savedPaymentList: []
};
