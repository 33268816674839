/** API calls for the applications */
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
  getTransactionProducts,
  saveTransactionProducts,
  getSubscriptionProducts,
  saveSubscriptionProducts,
  getPaymentProducts,
  createPaymentLink,
  authenticate,
  saveChargeProducts,
  getRefundableSubscription,
  refund,
  getListForPause,
  pauseSubscription,
  getCoupons,
  getSavedPaymentsMethod,
  createInvoiceLink,
  getClientSecret,
  createPaymentIntent
};

function getTransactionProducts(data) {
  return Vue.axios
    .get(`stripe/transaction-products`, { params: data })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
}

function getCoupons(data) {
  return Vue.axios
    .get(`stripe/coupons`, { params: data })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
}

function getClientSecret(data) {
  return Vue.axios
    .get(`stripe/getClientSecret`, { params: data })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
}

function createPaymentIntent(data) {
  return Vue.axios
    .post(`stripe/postPaymentIntent`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
}

function saveTransactionProducts(data) {
  return Vue.axios
    .post(`stripe/transaction-products`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
}

function saveChargeProducts(data) {
  return Vue.axios
    .post(`stripe/charge-customer`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
}

function getSubscriptionProducts(data) {
  return Vue.axios.get(`stripe/subscribe-transaction-products`, { params: data }).then((response) => {
    return response.data;
  });
}

function saveSubscriptionProducts(data) {
  return Vue.axios.post(`stripe/subscribe-transaction-products`, data).then((response) => {
    return response.data;
  });
}

function getPaymentProducts(data) {
  return Vue.axios.get(`stripe/create-payment-link`, { params: data }).then((response) => {
    return response.data;
  });
}

function createPaymentLink(data) {
  return Vue.axios.post(`stripe/create-payment-link`, data).then((response) => {
    return response.data;
  });
}

function createInvoiceLink(data) {
  return Vue.axios.post(`stripe/create-invoice-link`, data).then((response) => {
    return response.data;
  });
}

function authenticate(data) {
  return Vue.axios.post(`hubspot/crm-card/authenticate`, data).then((response) => {
    return response.data;
  });
}

function getRefundableSubscription(data) {
  return Vue.axios.get(`stripe/recent-transactions`, { params: data }).then((response) => {
    return response.data;
  });
}

function refund(data) {
  return Vue.axios.post(`stripe/refund`, data).then((response) => {
    return response.data;
  });
}

function getListForPause(data) {
  return Vue.axios.get(`stripe/recent-subscriptions`, { params: data }).then((response) => {
    return response.data;
  });
}

function pauseSubscription(data) {
  return Vue.axios.post(`stripe/pause-subscription`, data).then((response) => {
    return response.data;
  });
}

function getSavedPaymentsMethod(data) {
  return Vue.axios.get(`stripe/payment-methods`, { params: data }).then((response) => {
    return response.data;
  });
}